// src/App.js
import React from "react";
import { RecoilRoot } from "recoil";
import withAppContent from "assets/jss/withAppContent";

// import UserInitializer from "UserInitializer";
// import { UserProvider } from "UserContext";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute";
import UserPosts from "views/SectionsPage/Sections/UserPosts";
// Import your pages, for example:
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import SignupPage from "views/SignupPage/SignupPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import MembershipPage from "views/MembershipPage/MembershipPage.js";
import SinglePost from "views/SectionsPage/Sections/SinglePost";
import ForumPosts from "views/ForumPage/ForumPosts";
import ForumPostDetails from "views/ForumPage/SingleForumPost";
import SectionTeams from "views/SectionsPage/Sections/SectionTeams";

function App() {
  return (
    <RecoilRoot>
      <Router>
        <Switch>
          <Route path="/about-us" component={AboutUsPage} />
          {/* <Route path="/blog-post" component={BlogPostPage} /> */}
          <Route path="/user-posts" component={UserPosts} />
          <Route path="/news-posts/:postId" component={SinglePost} />
          <Route path="/news-posts" component={BlogPostsPage} />
          <ProtectedRoute
            path="/forum-posts/:postId"
            component={ForumPostDetails}
          />
          <ProtectedRoute path="/forum-posts" component={ForumPosts} />
          {/* <Route path="/components" component={ComponentsPage} /> */}
          <Route path="/contact-us" component={ContactUsPage} />
          {/* <Route path="/ecommerce-page" component={EcommercePage} />
          <Route path="/landing-page" component={LandingPage} /> */}
          <Route path="/login-page" component={LoginPage} />
          {/* <Route path="/pricing" component={PricingPage} /> */}
          <ProtectedRoute path="/profile-page" component={ProfilePage} />
          <Route path="/membership-page" component={MembershipPage} />
          {/* <Route path="/product-page" component={ProductPage} /> */}
          <Route path="/team" component={SectionTeams} />
          {/* <Route path="/sections" component={SectionsPage} /> */}
          {/* <Route path="/shopping-cart-page" component={ShoppingCartPage} /> */}
          <Route path="/signup-page" component={SignupPage} />
          <Route path="/error-page" component={ErrorPage} />
          <Route path="/" component={PresentationPage} />
        </Switch>
      </Router>
    </RecoilRoot>
  );
}

export default withAppContent(App);
