import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import clickableInputStyle from "../assets/jss/material-kit-pro-react/components/clickableInputStyle";

const useStyles = makeStyles(clickableInputStyle);

export default function ClickableInput(props) {
  const { onClick, placeholder } = props;
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      className={classNames(classes.clickableInput)}
      role="button"
      tabIndex={0}
    >
      {placeholder}
    </div>
  );
}

ClickableInput.propTypes = {
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
};
