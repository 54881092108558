/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import Footer from "components/Footer/Footer";

// @material-ui/icons
import Group from "@material-ui/icons/Group";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import FavoriteIcon from "@material-ui/icons/Favorite";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import SectionTestimonials from "views/SectionsPage/Sections/SectionTestimonials.js";
import SectionTestimonials2 from "views/SectionsPage/Sections/SectionTestimonials2.js";

const useStyles = makeStyles(projectsStyle);

export default function SectionProjects() {
  const classes = useStyles();

  return (
    <div className={classes.mobileViewContainer}>
      <Header
        color="info"
        brand="PCNA"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
      />

      <div>
        {/* Project 4 START */}
        <div className={classes.projects + " " + classes.projects4}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  classes.mlAuto +
                  " " +
                  classes.mrAuto +
                  " " +
                  classes.textCenter
                }
              >
                <h2 className={classes.title}>Membership Process</h2>
                <h5 className={classes.description}>
                  Being a member is as easy as following these steps.
                </h5>
                <div className={classes.sectionSpace} />
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem alignItems="center" xs={12} sm={9} md={7}>
                <SectionTestimonials />
                <InfoArea
                  className={classes.info4}
                  title="Who can be a Member of PCNA?"
                  description={
                    <p>
                      Filipino-Canadian with nursing education can be a regular
                      member! Filipino-Canadian allied health professionals can
                      be an Auxillary member.{" "}
                    </p>
                  }
                  icon={Group}
                  iconColor="pcna"
                />
                <InfoArea
                  className={classes.info4}
                  title="What is the Sign-Up Process?"
                  description={
                    <p>
                      Fill out our Google Forms and Register in the PCNA
                      website. The Membership Committee will review your
                      application and will approve your membership and online
                      account.
                    </p>
                  }
                  icon={VerifiedUserIcon}
                  iconColor="pcna"
                />
                <InfoArea
                  className={classes.info4}
                  title="What are the Benefits of being a PCNA Member"
                  description={
                    <span>
                      <p>
                        <strong>Receive Updates: </strong>
                        Members will receive our Quarterly News & Update
                        Journal: an update on our events and achievements!{" "}
                      </p>
                      <p>
                        <strong> Participate in Events: </strong>
                        Members can attend and participate in activities,
                        events, and gatherings organized by PCNA and/or allied
                        affiliated organizations.
                      </p>
                      <p>
                        <strong>Gain access to resources: </strong>
                        Members can ask questions in our forums and receive
                        responses from executives and other members. Members
                        will receive exclusive data and related educational,
                        professional advancement, and job opportunities.
                      </p>
                    </span>
                  }
                  icon={FavoriteIcon}
                  iconColor="pcna"
                />
              </GridItem>
            </GridContainer>
            <hr />
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  classes.mlAuto +
                  " " +
                  classes.mrAuto +
                  " " +
                  classes.textCenter
                }
              >
                <h2 className={classes.title}>
                  Being an Internationally Educated Nurse
                </h2>
                <h5 className={classes.description}>
                  In the Canadian context...
                </h5>
                <div className={classes.sectionSpace} />
              </GridItem>
              <GridItem
                xs={12}
                sm={5}
                md={6}
                className={
                  classes.mlAuto +
                  " " +
                  classes.mrAuto +
                  " " +
                  classes.textCenter
                }
              >
                <SectionTestimonials2 />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
