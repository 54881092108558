import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";

import Info from "components/Typography/Info";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";
import LikesAndComments from "./LikesandComments";
import DOMPurify from "dompurify";

const API_BASE_URL = "https://pcnassociation.com";
const useStyles = makeStyles(blogPostPageStyle);

function ForumPostDetails() {
  const classes = useStyles();
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const userCookie = Cookies.get("user");
  const userData = userCookie ? JSON.parse(userCookie) : null;
  const accessToken = userData ? userData.token : null;

  useEffect(() => {
    const fetchSinglePost = async (postId) => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/forum_posts/${postId}/`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
            },
          }
        );

        if (response.status < 200 || response.status >= 300) {
          throw new Error(`Request failed with status code ${response.status}`);
        }

        return response.data;
      } catch (error) {
        console.error(`Error fetching post with ID ${postId}:`, error);
      }
    };

    const fetchPost = async () => {
      const postData = await fetchSinglePost(postId);
      setPost(postData);
    };
    if (accessToken) {
      fetchPost();
    }
  }, [postId, accessToken]);

  if (!post) {
    return <div>Loading...</div>;
  }

  function timeDifference(current, previous) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else {
      return new Date(previous).toLocaleDateString();
    }
  }

  const infoContentStyle = {
    margin: "8px",
  };
  function addTargetBlank(node) {
    if (node.tagName && node.tagName.toLowerCase() === "a") {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    }
  }
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="info"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      {/* <Parallax image={require("assets/img/bg5.jpg")} filter="dark"> */}
      <div style={{ overflowX: "hidden" }}>
        <GridContainer justify="center">
          <GridItem md={8} className={classes.textCenter}>
            <h1 className={classes.customTitle}>{post.title}</h1>
            <Info>
              <div style={infoContentStyle}>
                {/* Add user first name, last name, and post date */}
                <p>
                  Posted by {post.user.first_name} {post.user.last_name}{" "}
                  {timeDifference(new Date(), new Date(post.updated_at))}
                </p>
              </div>
              <div style={infoContentStyle}>
                <h3 style={infoContentStyle}>{post.tag_category}</h3>
              </div>
            </Info>

            <div
              className={`${classes.description} ${classes.inlineLink}`}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(post.body, {
                  ADD_ATTR: ["target", "rel"],
                  FORBID_TAGS: ["style"],
                  TRANSFORM_NODE: addTargetBlank,
                }),
              }}
            ></div>
            <br />
            {post.image && (
              <div
                className={`${classes.postContainer} ${classes.customImageContainer}`}
              >
                <img src={post.image} alt="Post" className={classes.img} />
              </div>
            )}
          </GridItem>
          {/* Display your forum post here */}
          <LikesAndComments postId={postId} />
        </GridContainer>
      </div>
      {/* </Parallax> */}
    </div>
  );
}

export default ForumPostDetails;
