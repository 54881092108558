/*eslint-disable*/
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import WorkIcon from "@material-ui/icons/Work";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Favorite from "@material-ui/icons/Favorite";
import { Person } from "@material-ui/icons";
import { School } from "@material-ui/icons";
import { TermsAndConditionsContent } from "./termsAndConditions";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "assets/img/LoginRegister.png";

const useStyles = makeStyles(signupPageStyle);

export default function SignUpPage({ ...rest }) {
  const [checked, setChecked] = React.useState([1]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [prodes, setProDes] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const history = useHistory();

  const handleToggle = () => {
    setTermsAccepted((prevTermsAccepted) => !prevTermsAccepted);
  };
  const handleTermsModal = (open) => {
    setTermsModalOpen(open);
  };

  const displaySnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 5000);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      displaySnackbar("Passwords do not match");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        first_name: firstName, // Add this line
        last_name: lastName, // Add this line
        professional_designation: prodes,
        email,
        password,
        terms_accepted: termsAccepted, // Add this line
      }),
    };

    try {
      const response = await fetch(
        "https://pcnassociation.com/api/users/register/",
        requestOptions
      );

      if (response.ok) {
        console.log("Registration successful");
        history.push("/loginpage");
      } else {
        const errorData = await response.json();
        const errorMessages = Object.entries(errorData)
          .map(([key, value]) => `${key}: ${value}`)
          .join(", ");
        console.error("Registration failed:", response.statusText, errorData);
        displaySnackbar(`Registration failed: ${errorMessages}`);
      }
    } catch (error) {
      console.error("Error during registration:", error);
      displaySnackbar(`Error during registration: ${error.message}`);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.mobileViewContainer}>
      <Header
        absolute
        color="transparent"
        brand="PCNA"
        links={<HeaderLinks dropdownHoverColor="rose" />}
        {...rest}
      />

      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer
            className={classNames(
              classes.gridContainer,
              classes.mobileGridContainer
            )}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <GridItem>
              <Card className={classes.cardSignup}>
                <h2 className={classes.cardTitle}>Register</h2>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem>
                      <form className={classes.form}>
                        {/* Add the first name input */}
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Person
                                  className={classes.inputAdornmentIcon}
                                />
                              </InputAdornment>
                            ),
                            placeholder: "First Name...",
                            value: firstName,
                            onChange: (e) => setFirstName(e.target.value),
                          }}
                        />
                        {/* Add the last name input */}
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Person
                                  className={classes.inputAdornmentIcon}
                                />
                              </InputAdornment>
                            ),
                            placeholder: "Last Name...",
                            value: lastName,
                            onChange: (e) => setLastName(e.target.value),
                          }}
                        />

                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <WorkIcon
                                  className={classes.inputAdornmentIcon}
                                />
                              </InputAdornment>
                            ),
                            placeholder: "Professional Designation...",
                            value: prodes,
                            onChange: (e) => setProDes(e.target.value),
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Email...",
                            value: email,
                            onChange: (e) => setEmail(e.target.value),
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            placeholder: "Password...",
                            type: showPassword ? "text" : "password",
                            value: password,
                            onChange: (e) => setPassword(e.target.value),
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                >
                                  {showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            placeholder: "Confirm Password...",
                            type: showConfirmPassword ? "text" : "password",
                            value: confirmPassword,
                            onChange: (e) => setConfirmPassword(e.target.value),
                          }}
                        />
                        <FormControlLabel
                          classes={{
                            label: classes.label,
                          }}
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={handleToggle} // Update this line
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                              checked={termsAccepted} // Update this line
                            />
                          }
                          label={
                            <span>
                              I agree to the{" "}
                              <a
                                href="#pablo"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleTermsModal(true);
                                }}
                              >
                                terms and conditions
                              </a>
                              .
                            </span>
                          }
                        />
                        <div>
                        <span>
                          To become a full member, register in this{" "}
                          <a
                                href="https://forms.gle/WbPeCMnsW2ptxKA17" target="_blank"
                              >
                                Google Form
                              </a>
                        </span>
                        </div>
                        <div className={classes.textCenter}>
                          <Button round color="primary" onClick={handleSignUp}>
                            Get started
                          </Button>
                        </div>
                      </form>
                      {snackbarOpen && (
                        <SnackbarContent
                          message={
                            <span>
                              <b>Error:</b> {snackbarMessage}
                            </span>
                          }
                          close
                          color="danger"
                          icon="info_outline"
                          style={{ paddingTop: "100px" }}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
      <Dialog
        open={termsModalOpen}
        onClose={() => handleTermsModal(false)}
        aria-labelledby="terms-dialog-title"
        aria-describedby="terms-dialog-description"
      >
        <DialogTitle id="terms-dialog-title">Terms and Conditions</DialogTitle>
        <DialogContent>
          <DialogContentText id="terms-dialog-description">
            <TermsAndConditionsContent />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleTermsModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
