import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";
import UserPosts from "views/SectionsPage/Sections/UserPosts";

const useStyles = makeStyles(sectionPillsStyle);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <UserPosts />
    </div>
  );
}
