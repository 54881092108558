import React, { useRef, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useMediaQuery } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components

import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    clickable, // Add this line
    onClick, // Add this line
  } = props;
  const classes = useStyles();
  const inputRef = useRef(null); // Add this line
  const isMobileDevice = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const currentInputRef = inputRef.current;
    if (currentInputRef) {
      currentInputRef.focus(); // Add this line to focus on the input when the component mounts
      currentInputRef.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      if (currentInputRef) {
        currentInputRef.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);
  const handleKeyDown = (event) => {
    const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
    if (arrowKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [clickable ? classes.clickableInput : ""]: clickable, // Add this line
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  let newInputProps = {
    maxLength: inputProps ? inputProps.maxLength : undefined,
    minLength: inputProps ? inputProps.minLength : undefined,
  };
  return (
    <div
      className={`${
        inputRef.current === document.activeElement && isMobileDevice
          ? classes.focusedWrapper
          : ""
      }`}
    >
      <FormControl {...formControlProps} className={formControlClasses}>
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Input
          ref={inputRef} // Add this line
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          id={id}
          {...inputProps}
          inputProps={newInputProps}
          onKeyDown={handleKeyDown}
          readOnly={clickable} // Add this line
          onClick={onClick} // Add this line
        />

        {error ? (
          <Clear className={classes.feedback + " " + classes.labelRootError} />
        ) : success ? (
          <Check
            className={classes.feedback + " " + classes.labelRootSuccess}
          />
        ) : null}
      </FormControl>
    </div>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
};
