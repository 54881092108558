import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Cookies from "js-cookie";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import DOMPurify from "dompurify";
import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";

const useStyles = makeStyles(blogPostPageStyle);

function LikesAndComments({ postId }) {
  const [comments, setComments] = useState([]);
  const [likes, setLikes] = useState([]);
  const [likedByCurrentUser, setLikedByCurrentUser] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentBody, setEditedCommentBody] = useState("");
  const userCookie = Cookies.get("user");
  const userData = userCookie ? JSON.parse(userCookie) : { user_id: null };
  const accessToken = userData ? userData.token : null;
  const user_id = userData ? userData.user_id : null;

  const classes = useStyles();

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "header",
    "list",
    "bullet",
    "indent",
    "script",
    "direction",
    "size",
    "color",
    "background",
    "font",
    "align",
    "link",
    "image",
    "video",
  ];

  const fetchComments = useCallback(async () => {
    const response = await axios.get(
      `https://pcnassociation.com/api/forum_posts/${postId}/comments/`,
      {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      }
    );
    setComments(response.data);
  }, [postId, accessToken]);

  const fetchLikes = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://pcnassociation.com/api/forum_posts/${postId}/likes/`,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
          },
        }
      );
      const fetchedLikes = response.data;

      setLikes(fetchedLikes);
    } catch (error) {
      console.error("Error fetching likes:", error);
    }
  }, [postId, accessToken]);

  useEffect(() => {
    if (accessToken) {
      fetchComments();
      fetchLikes();
    }
  }, [postId, accessToken, user_id, fetchComments, fetchLikes]);

  useEffect(() => {
    likes.forEach((like) => {});

    const currentUserLiked = likes.some(
      (like) => like.user === userData.user_id
    );

    setLikedByCurrentUser(currentUserLiked);
  }, [likes, userData]);

  const handleLike = async () => {
    try {
      if (likedByCurrentUser) {
        const like = likes.find((l) => l.user === userData.user_id);
        if (like) {
          await axios.delete(
            `https://pcnassociation.com/api/forum_posts/${postId}/likes/${like.id}/`,
            {
              headers: {
                Authorization: `Token ${accessToken}`,
              },
            }
          );
          setLikes(likes.filter((l) => l.id !== like.id));
          setLikedByCurrentUser(false);
        } else {
          console.error("Like not found for the current user.");
        }
      } else {
        const response = await axios.post(
          `https://pcnassociation.com/api/forum_posts/${postId}/likes/`,
          { post: postId },
          {
            headers: {
              Authorization: `Token ${accessToken}`,
            },
          }
        );
        setLikes([...likes, response.data]);
        setLikedByCurrentUser(true);
      }
    } catch (error) {
      console.error("Error updating like:", error);
      alert("There was an error updating the like. Please try again later.");
    }
  };

  const handleCommentChange = (value) => {
    setNewComment(value);
  };
  const handleEditedCommentChange = (value) => {
    setEditedCommentBody(value);
  };

  const handleEditClick = (commentId, commentBody) => {
    setEditingCommentId(commentId);
    setEditedCommentBody(commentBody);
  };

  const handleCommentSubmit = async () => {
    try {
      const response = await axios.post(
        `https://pcnassociation.com/api/forum_posts/${postId}/comments/`,
        { post: postId, body: newComment },
        {
          headers: {
            Authorization: `Token ${accessToken}`,
          },
        }
      );
      setComments([...comments, response.data]);
      setNewComment("");
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleCommentEdit = async (commentId, newBody) => {
    try {
      const response = await axios.put(
        `https://pcnassociation.com/api/forum_posts/${postId}/comments/${commentId}/`,
        { post: postId, body: newBody },
        {
          headers: {
            Authorization: `Token ${accessToken}`,
          },
        }
      );
      setComments(
        comments.map((comment) =>
          comment.id === commentId ? response.data : comment
        )
      );
      setEditingCommentId(null);
      setEditedCommentBody("");
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  async function handleCommentDelete(commentId) {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this comment?"
    );

    if (confirmDelete) {
      try {
        await axios.delete(
          `https://pcnassociation.com/api/forum_posts/${postId}/comments/${commentId}/`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
            },
          }
        );
        // Refresh comments or update the state here
        setComments(comments.filter((comment) => comment.id !== commentId));
      } catch (error) {
        console.error("Error deleting comment:", error);
      }
    }
  }

  function timeDifference(current, previous) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else {
      return new Date(previous).toLocaleDateString();
    }
  }
  function addTargetBlank(node) {
    if (node.tagName && node.tagName.toLowerCase() === "a") {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    }
  }

  return (
    <div className={classes.customMobileMargins}>
      <Box
        className={classes.customMobileMargins}
        mt={3}
        display="flex"
        alignItems="center"
      >
        <Button
          variant="text"
          color={likedByCurrentUser ? "secondary" : "default"}
          onClick={handleLike}
          startIcon={
            likedByCurrentUser ? <FavoriteIcon /> : <FavoriteBorderIcon />
          }
        >
          {likedByCurrentUser ? "Unlike" : "Like"}
        </Button>
        <Box ml={2}>{likes.length}</Box>
      </Box>
      <div className={classes.customMobileMargins}>
        <h3>Comments</h3>
        {comments.map((comment) => (
          <div
            key={comment.id}
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              border: "2px solid #ccc",
              borderRadius: "5px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div style={{ alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={`https://pcnassociation.com/${comment.user.profile_photo}`}
                  alt="Profile"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                />
                <strong>
                  {comment.user.first_name} {comment.user.last_name}
                </strong>
                <p style={{ marginLeft: "10px", fontSize: "12px" }}>
                  {timeDifference(new Date(), new Date(comment.created_at))}
                </p>
              </div>
              <div
                className={`${classes.description} ${classes.inlineLink}`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(comment.body, {
                    ADD_ATTR: ["target", "rel"],
                    FORBID_TAGS: ["style"],
                    TRANSFORM_NODE: addTargetBlank,
                  }),
                }}
              ></div>
              {comment.user.id === userData.user_id && (
                <div style={{ alignItems: "center" }}>
                  {editingCommentId === comment.id ? (
                    <>
                      <ReactQuill
                        theme="snow"
                        value={editedCommentBody}
                        onChange={handleEditedCommentChange}
                        modules={modules}
                        formats={formats}
                        placeholder="New Comment"
                        style={{ marginBottom: "10px" }}
                      />

                      <EditOutlinedIcon
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() =>
                          handleCommentEdit(comment.id, editedCommentBody)
                        }
                      />
                    </>
                  ) : (
                    <>
                      <EditOutlinedIcon
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() =>
                          handleEditClick(comment.id, comment.body)
                        }
                      />
                      <DeleteOutlinedIcon
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() => handleCommentDelete(comment.id)}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
        <div>
          <ReactQuill
            theme="snow"
            value={newComment}
            onChange={handleCommentChange}
            modules={modules}
            formats={formats}
            placeholder="New Comment"
            style={{ marginBottom: "10px" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCommentSubmit}
            style={{ marginTop: "10px" }}
          >
            Submit Comment
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LikesAndComments;
