import {
  container,
  title,
  main,
  blackColor,
  mainRaised,
} from "assets/jss/material-kit-pro-react.js";

const blogPostPageStyle = {
  container: {
    ...container,
    paddingTop: "70px",
    zIndex: "2",
    "@media (min-width: 768px)": {
      paddingTop: "150px",
    },
  },

  customMobileMargins: {
    marginLeft: "300px",
    marginRight: "300px",

    "@media (max-width: 767px)": {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "90%", // Customize this value for the desired content width on mobile view
    },
  },

  container2: {
    ...container,
    paddingTop: "70px",
    zIndex: "2",
    "@media (min-width: 768px)": {
      paddingTop: "50px",
    },
  },
  img: {
    maxHeight: "500px",

    objectFit: "contain",
    width: "100%", // Add this line to make the image responsive
  },

  customImageContainer: {
    "@media (min-width: 768px)": {
      margin: "16px 8px", // Customize these values for the desired margins on mobile view
      padding: "8px", // Customize this value for the desired padding on mobile view
    },
  },

  textCenter: {
    textAlign: "center",
  },
  title: {
    ...title,
    color: blackColor,
  },
  customTitle: {
    ...title,
    paddingTop: "80px",
    color: blackColor,
    "@media (min-width: 768px)": {
      paddingTop: "120px",
    },
  },
  subtitle: {
    color: blackColor,
  },
  main: {
    ...main,
    ...mainRaised,
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },

  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
};

export default blogPostPageStyle;
