import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ExploreIcon from "@material-ui/icons/Explore";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import FavoriteIcon from "@material-ui/icons/Favorite";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServices() {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState({
    mission: false,
    goals: false,
    values: false,
  });

  const handleExpandClick = (section) => {
    setExpanded({ ...expanded, [section]: !expanded[section] });
  };

  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          xs={9}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Mission, Vision, Goals</h2>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <InfoArea
            className={classes.info4}
            title="Mission"
            description={
              <span>
                <Collapse in={expanded.mission} collapsedHeight="6.5em">
                  <p>
                    We enhance and strengthen the image of the Filipino Canadian
                    nurse through education, mentoring, and networking.
                  </p>

                  <p>
                    We advocate for streamlining the credentialing process for
                    the IEN in Canada.
                  </p>

                  <p>
                    We are committed to increasing our peer-to-peer mentoring
                    and support for the common good of the people we serve and
                    our membership.
                  </p>
                </Collapse>
                <span
                  className={classes.expandableSpan}
                  onClick={() => handleExpandClick("mission")}
                >
                  {expanded.mission ? "Show less..." : "Find more..."}
                </span>
              </span>
            }
            icon={ExploreIcon}
            iconColor="pcna"
          />
        </GridItem>

        <GridItem md={4} sm={4}>
          <InfoArea
            className={classes.info4}
            title="Goals"
            description={
              <span>
                <Collapse in={expanded.goals} collapsedHeight="5em">
                  <p>
                    To continue to exemplify our core values and beliefs as we
                    tend to the people we serve.
                  </p>

                  <p>
                    To set the stage for future collaborations between the
                    Canadian Nursing Universities and that of the Centres of
                    Excellence Nursing education providers from the Philippines.
                  </p>

                  <p>
                    To connect and collaborate with other Professional Nursing
                    Association to enhance the profession.
                  </p>
                </Collapse>
                <span
                  className={classes.expandableSpan}
                  onClick={() => handleExpandClick("goals")}
                >
                  {expanded.goals ? "Show less..." : "Find more..."}
                </span>
              </span>
            }
            icon={TrendingUpIcon}
            iconColor="pcna"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            className={classes.info4}
            title="Values"
            description={
              <span>
                <Collapse in={expanded.values} collapsedHeight="8em">
                  <p>
                    Competence: The ability to demonstrate and perform what is
                    asked of us and do it efficiently guide by the art and
                    science of nursing
                  </p>
                  <p>
                    Resourcefulness: The act of creating new ways of getting the
                    work done efficiently and effectively
                  </p>
                  <p>
                    {" "}
                    Empowerment: The art and process of becoming stronger to
                    prove your cause with credibility, integrity, and
                    authenticity
                  </p>

                  <p>Advocacy: The art of working together for a common goal</p>
                  <p>
                    Transformational: The art of ongoing evaluation of what we
                    do and looking for ways to do it better
                  </p>

                  <p>
                    Empathy: The art of appreciating and understanding one’s
                    situation and providing a lending hand
                  </p>
                </Collapse>
                <span
                  className={classes.expandableSpan}
                  onClick={() => handleExpandClick("values")}
                >
                  {expanded.values ? "Show less..." : "Find more..."}
                </span>
              </span>
            }
            icon={FavoriteIcon}
            iconColor="pcna"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
