import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardAvatar from "components/Card/CardAvatar.js";
import ClickableInput from "components/ClickableInput.js";
import { makeStyles } from "@material-ui/core/styles";
import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import defaultAvatar from "assets/img/placeholder.jpg";

const useStyles = makeStyles(projectsStyle);

export default function AvatarWithInput(props) {
  const { avatarUrl, onClick, placeholder } = props;
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const inputStyle = {
    width: "100%",
    fontSize: windowWidth > 768 ? "1.5rem" : "1rem", // Change the font size based on windowWidth
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const displayAvatarUrl = avatarUrl ? avatarUrl : defaultAvatar;

  return (
    <div className={classes.container}>
      <GridContainer container alignItems="center" spacing={0}>
        <GridItem xs={2} sm={4} md={1} style={{ padding: 0 }}>
          <CardAvatar profile>
            <img src={displayAvatarUrl} alt="user avatar" />
          </CardAvatar>
        </GridItem>
        <GridItem xs={10} sm={11}>
          <ClickableInput
            onClick={onClick}
            placeholder={placeholder}
            style={inputStyle}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
