import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  app: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  appContent: {
    flex: 1,
    position: "relative",
    overflow: "auto",
  },
});

const withAppContent = (WrappedComponent) => {
  const AppContentWrapper = (props) => {
    const classes = useStyles();
    return (
      <div className={classes.app}>
        <div className={classes.appContent}>
          <WrappedComponent {...props} />
        </div>
      </div>
    );
  };
  return AppContentWrapper;
};

export default withAppContent;
