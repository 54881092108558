import {
  mrAuto,
  mlAuto,
  description,
  cardTitle,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const teamStyle = {
  mrAuto,
  mlAuto,
  title: {
    padding: "20px",
  },
  mobileViewContainer: {
    maxWidth: "100%",
    overflowX: "hidden",
    "@media (min-width: 600px)": {
      maxWidth: "none",
      overflowX: "visible",
    },
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`, // Add this line
  },
  description: {
    ...description,
    marginBottom: "80px",
  },
  cardTitle,
  cardDescription: {
    color: grayColor[0],
  },
  team: {
    padding: "80px 0px",
  },
  textCenter: {
    textAlign: "center!important",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  textMuted: {
    color: grayColor[7] + " !important",
  },
  justifyContent: {
    justifyContent: "center!important",
  },
};

export default teamStyle;
