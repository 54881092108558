import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
// Import Material UI components
import { makeStyles } from "@material-ui/core/styles";
import { Button, InputLabel, Typography } from "@material-ui/core";

// Import custom components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";
import Info from "components/Typography/Info";
import CustomInput from "components/CustomInput/CustomInput";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { MenuItem, Select } from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import DOMPurify from "dompurify";
import PostForm from "./PostForm";

const useStyles = makeStyles(blogsStyle);

const modules = {
  toolbar: [
    // Add any toolbar options you need
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ],
};

const formats = [
  // Add any formats you need
  "bold",
  "italic",
  "underline",
  "header",
  "list",
  "bullet",
  "link",
  "image",
];

function UserPosts({ staticContext, ...rest }) {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [imageUploadKey, setImageUploadKey] = React.useState(0);
  const [category, setCategory] = useState("internal_update");
  // const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [filterCategory, setFilterCategory] = useState("all");
  const [sortOrder, setSortOrder] = useState("newest");
  // const [sortedPosts, setSortedPosts] = useState([]);
  const [componentIsMounted, setComponentIsMounted] = useState(true);

  const userCookie = Cookies.get("user");
  const userData = userCookie ? JSON.parse(userCookie) : null;
  const currentUserId = userData ? userData.user_id : null;
  const accessToken = userData ? userData.token : null;

  const classes = useStyles();

  const sortPostsByDate = useCallback(
    (posts) => {
      try {
        const sortedPosts = posts.sort((a, b) => {
          const dateA = new Date(a.updated_at);
          const dateB = new Date(b.updated_at);

          if (sortOrder === "newest") {
            return dateB - dateA;
          } else {
            return dateA - dateB;
          }
        });

        return sortedPosts;
      } catch (error) {
        console.error("Error sorting user posts:", error);
      }
    },
    [sortOrder]
  );

  useEffect(() => {
    const fetchAllPosts = async () => {
      try {
        const response = await axios.get(
          "https://pcnassociation.com/api/posts/"
        );
        const allPosts = response.data;

        setUserPosts(allPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchAllPosts();

    return () => {
      setComponentIsMounted(false);
    };
  }, [accessToken, sortOrder, componentIsMounted]);

  const fetchUserPosts = async () => {
    try {
      const response = await axios.get("https://pcnassociation.com/api/posts/");

      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching user posts:", error);
    }
  };

  const handleOpenEditModal = (post) => {
    setTitle(post.title);
    setBody(post.body);
    setImage(post.image);
    setCategory(post.category); // Initialize the category variable
    setEditingPost(post);
    // setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditingPost(null);
    // setEditModalOpen(false);
  };

  const handleEditPost = async (e) => {
    e.preventDefault();

    if (!accessToken || !editingPost) {
      console.error("Access token not found or no post to edit");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("category", category);

      const isImageChanged = image !== editingPost.image;
      if (isImageChanged) {
        formData.append("image", image);
      }

      const response = await axios.put(
        `https://pcnassociation.com/api/posts/${editingPost.id}/`,
        formData,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            ...(isImageChanged && { "Content-Type": "multipart/form-data" }),
          },
        }
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      // Log a message when the post is edited successfully
      console.log("Post edited successfully");
      setTitle("");
      setBody("");
      setImage(null);
      setCategory("internal_update"); // Reset the category state
      setImageUploadKey(imageUploadKey + 1); // Reset the ImageUpload component

      // Fetch the updated list of user posts after successful editing
      const updatedPosts = await fetchUserPosts();

      setUserPosts(updatedPosts);
      handleEditModalClose();
    } catch (error) {
      console.error("Error editing post:", error);
      if (error.response && error.response.data) {
        console.error("Server response data:", error.response.data);
      }
    }
  };

  const handleDeletePost = async (postId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this post?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(`https://pcnassociation.com/api/posts/${postId}/`, {
          headers: {
            Authorization: `Token ${accessToken}`,
          },
        });

        // Filter out the deleted post and update the state
        const updatedPosts = userPosts.filter((post) => post.id !== postId);
        setUserPosts(updatedPosts);
      } catch (error) {
        console.error(`Error deleting post with ID ${postId}:`, error);
      }
    }
  };

  function timeDifference(current, previous) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else {
      return new Date(previous).toLocaleDateString();
    }
  }
  const getCategoryDisplayName = (category) => {
    const categoryMapping = {
      internal_update: "PCNA Update",
      news_update: "News Update",
    };
    return categoryMapping[category] || "Unknown";
  };
  const infoContentStyle = {
    margin: "8px",
  };

  return (
    <div className="cd-section" {...rest}>
      <PostForm />
      {/* Add a dropdown for selecting the category to render */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor="info"
          buttonText="Category"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <div
              onClick={() => setFilterCategory("all")}
              className={classes.dropdownLink}
            >
              All
            </div>,
            <div
              onClick={() => setFilterCategory("news_update")}
              className={classes.dropdownLink}
            >
              News Update
            </div>,
            <div
              onClick={() => setFilterCategory("internal_update")}
              className={classes.dropdownLink}
            >
              PCNA Update
            </div>,
          ]}
        />
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor="info"
          buttonText="Update Date"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <div
              onClick={() => setSortOrder("newest")}
              className={classes.dropdownLink}
            >
              Newest
            </div>,
            <div
              onClick={() => setSortOrder("oldest")}
              className={classes.dropdownLink}
            >
              Oldest
            </div>,
          ]}
        />
      </div>

      {/* Render submitted blog posts and Edit*/}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            {sortPostsByDate(
              (userPosts || []).filter(
                (post) =>
                  filterCategory === "all" || post.category === filterCategory
              )
            ).map((post, index) => (
              <GridItem
                key={index}
                xs={12}
                sm={8}
                md={8}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <div className={classes.postContainer}>
                  <Card plain blog className={classes.card4}>
                    <CardHeader image plain className={classes.imgContainer}>
                      <Info>
                        <div style={infoContentStyle}>
                          <h4 className={classes.cardCategory}>
                            {getCategoryDisplayName(post.category)}
                          </h4>
                        </div>
                      </Info>
                      <Info>
                        <div style={infoContentStyle}>
                          {/* Add user first name, last name, and post date */}
                          <p>
                            Posted by {post.user.first_name}{" "}
                            {post.user.last_name}{" "}
                            {timeDifference(
                              new Date(),
                              new Date(post.created_at)
                            )}
                          </p>
                        </div>
                      </Info>
                      <h3 className={classes.cardTitle}>{post.title}</h3>
                      <div
                        className={`${classes.description} ${classes.inlineLink}`}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(post.body, {
                            ADD_ATTR: ["target", "rel"],
                            FORBID_TAGS: ["style"],
                          }),
                        }}
                      ></div>

                      <a href="#pablito" onClick={(e) => e.preventDefault()}>
                        {post.image && (
                          <img
                            src={post.image}
                            alt="..."
                            className={classes.img}
                          />
                        )}
                      </a>
                    </CardHeader>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CardBody plain>
                        <Button
                          component={Link}
                          to={`/news-posts/${post.id}`}
                          className={classes.roundedButton}
                          color="primary"
                        >
                          Read More
                        </Button>

                        {currentUserId === post.user.id && (
                          <div>
                            <Button
                              className={classes.roundedButton}
                              color="primary"
                              onClick={() => handleDeletePost(post.id)}
                            >
                              Delete
                            </Button>
                            <Button
                              className={classes.roundedButton}
                              color="primary"
                              onClick={() => handleOpenEditModal(post)}
                            >
                              Edit
                            </Button>
                          </div>
                        )}
                      </CardBody>
                    </div>

                    {editingPost && editingPost.id === post.id && (
                      <form
                        onSubmit={handleEditPost}
                        className={classes.form}
                        justify="center"
                      >
                        <h2>Edit Post</h2>
                        <CardBody signup>
                          <CustomInput
                            id={`edit_title_${post.id}`}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              placeholder: "Title...",
                              type: "text",
                              value: title,
                              onChange: (e) => setTitle(e.target.value),
                            }}
                          />
                          <ReactQuill
                            theme="snow"
                            value={body}
                            onChange={setBody}
                            modules={modules}
                            formats={formats}
                            id={`edit_title_${post.id}`}
                            style={{ paddingBottom: "20px" }}
                          />
                          <InputLabel htmlFor={`edit_category_${post.id}`}>
                            Category
                          </InputLabel>
                          <Select
                            labelId={`edit_category_label_${post.id}`}
                            id={`edit_category_${post.id}`}
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <MenuItem value="internal_update">
                              PCNA Update
                            </MenuItem>
                            <MenuItem value="news_update">News Update</MenuItem>
                          </Select>
                          <input
                            type="file"
                            id={`edit_image_${post.id}`}
                            key={imageUploadKey}
                            hidden
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                          <label
                            htmlFor={`edit_image_${post.id}`}
                            className={classes.uploadButton}
                          >
                            <Button
                              component="span"
                              color="primary"
                              size="large"
                              variant="contained"
                            >
                              Upload Image
                            </Button>
                          </label>
                          {image && (
                            <Typography variant="body1">
                              Selected image: {image.name}
                            </Typography>
                          )}
                        </CardBody>
                        <Button
                          type="submit"
                          color="primary"
                          className={classes.submitButton}
                        >
                          Save Changes
                        </Button>
                        <Button
                          color="danger"
                          className={classes.cancelButton}
                          onClick={handleEditModalClose}
                        >
                          Cancel
                        </Button>
                      </form>
                    )}
                  </Card>
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default UserPosts;
