import { infoColor } from "assets/jss/material-kit-pro-react";
import {
  container,
  title,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const blogsSection = {
  container,

  title,
  coloredShadow,
  cardTitle: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  mlAuto,
  mrAuto,
  description,
  blog: {
    padding: "20px 0",
  },
  cardCategory: {
    marginBottom: "0",

    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",

      lineHeight: "0",
    },
  },
  mobileButton: {
    "@media (max-width: 600px)": {
      fontSize: "0.7rem",
    },
  },

  inlineLink: {
    "& a": {
      display: "inline",
    },
  },

  uploadButton: {
    padding: "50px",
    "@media (max-width: 600px)": {
      fontSize: "0.7rem",
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  postContainer: {
    position: "relative",
    overflow: "hidden",
    border: "2px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    transition: "border-color 0.3s",
    marginTop: "1px",
    marginBottom: "1px",
    marginRight: "9px",
    marginLeft: "9px",
    "@media (hover: hover)": {
      "&:hover": {
        borderColor: infoColor[0],
        marginRight: "10px",
        marginLeft: "10px",
      },
    },
  },
  mobileViewContainer: {
    maxWidth: "100%",
    overflowX: "hidden",
    "@media (min-width: 600px)": {
      maxWidth: "none",
      overflowX: "visible",
    },
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`, // Add this line
  },
  tOverlay: {
    position: "absolute",
    top: -10,
    left: -10,
    bottom: -10,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    opacity: 0,
    transition: "opacity 0.3s",
    "@media (hover: hover)": {
      "&:hover": {
        opacity: 0.3,
      },
    },
  },
  // card4: {
  //   marginBottom: "80px",
  //   "@media (hover: hover)": {
  //     "&:hover $postOverlay": {
  //       opacity: 1,
  //     },
  //   },
  // },

  imgContainer: {
    overflow: "hidden",
    paddingRight: "40px",
    justifyContent: "center",
  },
  img: {
    maxHeight: "500px",
    objectFit: "contain",
  },
  description1: {
    ...description,
    lineHeight: "1.313rem",
  },
  author: {
    "& a": {
      color: grayColor[1],
      textDecoration: "none",
    },
  },
  card: {
    marginBottom: "80px",
    marginRight: "60px",
  },
  card4: {
    marginTop: "-3px",
    marginBottom: "-3px",
    marginLeft: "20px",
    marginRight: "60px",
    textAlign: "left",
  },
};

export default blogsSection;
