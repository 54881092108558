import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

// Import Material UI components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Check from "@material-ui/icons/Check";
// Import custom components
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { MenuItem, Select } from "@material-ui/core";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import AvatarWithInput from "components/AvatarWithInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles

function PostForm({ onSubmit, post, onEdit }) {
  // Use the passed post object for editing or initialize with default values
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [imageUploadKey, setImageUploadKey] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [category, setCategory] = useState("internal_update");
  const [userProfile, setUserProfile] = useState(null);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const userCookie = Cookies.get("user");
  const userData = userCookie ? JSON.parse(userCookie) : null;
  const currentUserId = userData ? userData.user_id : null;
  const accessToken = userData ? userData.token : null;
  const useStyles = makeStyles(blogsStyle);
  const classes = useStyles();

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUserId && accessToken) {
        const response = await axios.get(
          `https://pcnassociation.com/api/users/${currentUserId}/`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const userData = response.data;
          setUserProfile(userData);
          // console.log("userData:", userData);
          // console.log(userData.is_staff);
        } else {
          console.error("Failed to fetch user data:", response.statusText);
        }
      }
    };

    fetchUserData();
  }, [currentUserId, accessToken]);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "header",
    "list",
    "bullet",
    "indent",
    "script",
    "direction",
    "size",
    "color",
    "background",
    "font",
    "align",
    "link",
    "image",
    "video",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!accessToken || userProfile.is_staff === "false") {
      console.error("Access token not found");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("category", category); // Include the category in the form data
      if (image) {
        formData.append("image", image);
      }

      const response = await axios.post(
        "https://pcnassociation.com/api/posts/",
        formData,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      // Log a message when the post is successful
      console.log("Post submitted successfully");
      showSnackbar("Post submitted successfully");
      setTitle("");
      setBody("");
      setImage(null);
      setImageUploadKey(imageUploadKey + 1); // Reset the ImageUpload component

      // Fetch the updated list of user posts after successful submission
      const updatedPosts = await fetchUserPosts();

      setUserPosts(updatedPosts);

      // Close the modal after successful submission
      handleModalClose();
    } catch (error) {
      console.error("Error submitting post:", error);
      showSnackbar("Error submitting post");
    }
  };

  const fetchUserPosts = async () => {
    try {
      const response = await axios.get(
        "https://pcnassociation.com/api/posts/",
        {
          headers: {
            Authorization: `Token ${accessToken}`,
          },
        }
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching user posts:", error);
    }
  };

  const handleImageChange = (file) => {
    setImage(file);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleModalClose = (event, reason) => {
    if (reason === "backdropClick") {
      // Show your warning here
      console.warn("You clicked away from the modal!");
    }
    setOpenModal(false);
  };
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarVisible(true);
    setTimeout(() => {
      hideSnackbar();
    }, 6000);
  };

  const hideSnackbar = () => {
    setSnackbarVisible(false);
  };

  return (
    <div className="cd-section">
      {snackbarVisible && (
        <SnackbarContent
          style={{
            position: "fixed",
            bottom: "20px",
            left: "20px",
            zIndex: 1500,
          }}
          message={
            <span>
              <b>{snackbarMessage}</b>
            </span>
          }
          close
          color="success"
          icon={Check}
        />
      )}
      {/* Add form for user input */}
      <div className={classes.container}>
        <GridContainer justify="center" direction="row">
          <GridItem xs={10} sm={12} md={8}>
            {userProfile && userProfile.is_staff && (
              <AvatarWithInput
                avatarUrl={userProfile && userProfile.profile_photo}
                onClick={handleOpenModal}
                placeholder="Create Post..."
              />
            )}
            <div>
              <h3>What's New</h3>
              <ul>
                {userPosts.map((post) => (
                  <li key={post.id}>{post.title}</li>
                ))}
              </ul>
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <div>
        {/* Create post modal */}
        <Dialog
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="create-post-modal-title"
          aria-describedby="create-post-modal-description"
          fullWidth
          maxWidth="md"
        >
          <form onSubmit={handleSubmit} minWidth="200px" justify="center">
            <DialogContent>
              <h2>Create Post</h2>
              <CardBody signup>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className={classes.select}
                >
                  <MenuItem value="internal_update">PCNA Update</MenuItem>
                  <MenuItem value="news_update">News Update</MenuItem>
                </Select>
                <CustomInput
                  id="title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "Title...",
                    type: "text",
                    value: title,
                    onChange: (e) => setTitle(e.target.value),
                  }}
                />
                <ReactQuill
                  theme="snow"
                  value={body}
                  onChange={(newBody) => {
                    setBody(newBody);
                  }}
                  modules={modules}
                  formats={formats}
                />
                <ImageUpload
                  key={imageUploadKey}
                  addButtonProps={{
                    round: true,
                  }}
                  changeButtonProps={{
                    round: true,
                  }}
                  removeButtonProps={{
                    round: true,
                    color: "danger",
                  }}
                  onChange={(file) => handleImageChange(file)}
                />
              </CardBody>

              <CardBody>
                <Button color="primary" size="large" type="submit">
                  Submit
                </Button>
              </CardBody>
            </DialogContent>
          </form>
        </Dialog>
      </div>
    </div>
  );
}

export default PostForm;
