import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import servicesStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          xs={9}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classes.title}> Who We Are</h2>
          <h4 className={classes.description}>
            We are a community of diverse nurses (RN,RON,NP, LPN, Nursing Aides)
            educated outside Canada. We are often referred to as the
            Internationally Educated Nurses.
          </h4>
          <h4 className={classes.description}>
            We also have Canadian Educated Nurses of Filipino Ancestry, Together
            we are aiming to enhance the profile of our existence as it relates
            to our assimilation to Canadian Nursing and our overall contribution
            to the health of Canadians.
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}
