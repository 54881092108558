/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Edit from "@material-ui/icons/Edit";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import ForumPostsProfile from "views/ForumPage/ForumPostsProfile";

import Cookies from "js-cookie";

import christian from "assets/img/faces/christian.jpg";
import oluEletu from "assets/img/examples/olu-eletu.jpg";
import clemOnojeghuo from "assets/img/examples/clem-onojeghuo.jpg";
import cynthiaDelRio from "assets/img/examples/cynthia-del-rio.jpg";
import mariyaGeorgieva from "assets/img/examples/mariya-georgieva.jpg";
import clemOnojegaw from "assets/img/examples/clem-onojegaw.jpg";
import darrenColeshill from "assets/img/examples/darren-coleshill.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import marc from "assets/img/faces/marc.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";
// import withAuth from "withAuth";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

function ProfilePage({ ...rest }) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [userData, setUserData] = React.useState(null);
  const formRef = React.useRef(null);
  const [profilePhoto, setProfilePhoto] = React.useState(
    require("assets/img/placeholder.jpg")
  );
  const [profileBanner, setProfileBanner] = React.useState(
    require("assets/img/examples/city.jpg")
  );
  const serverBaseURL = "https://pcnassociation.com:8000";
  const [formValues, setFormValues] = React.useState({
    first_name: "",
    last_name: "",
    bio: "",
    profile_photo: null,
    profile_banner: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: files[0],
    }));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userCookie = Cookies.get("user");
      const user = userCookie ? JSON.parse(userCookie) : null;

      if (user && user.user_id) {
        const response = await fetch(
          `https://pcnassociation.com/api/users/${user.user_id}/`,
          {
            headers: {
              Authorization: `Token ${user.token}`,
            },
          }
        );

        if (response.ok) {
          const userData = await response.json();
          setUserData(userData);
        } else {
          console.error("Failed to fetch user data:", await response.text());
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      const img = new Image();
      img.src = userData.profile_photo;
      img.onload = () => {
        setProfilePhoto(userData.profile_photo);
      };
      img.onerror = () => {
        setProfilePhoto(require("assets/img/placeholder.jpg"));
      };

      const bannerImg = new Image();
      bannerImg.src = userData.profile_banner;
      bannerImg.onload = () => {
        setProfileBanner(userData.profile_banner);
      };
      bannerImg.onerror = () => {
        setProfileBanner(require("assets/img/examples/city.jpg"));
      };
    }
  }, [userData]);

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userCookie = Cookies.get("user");
    const user = userCookie ? JSON.parse(userCookie) : null;

    if (userData && userData.id) {
      const formData = new FormData();

      Object.entries(formValues).forEach(([key, value]) => {
        if (value) formData.append(key, value);
      });

      const response = await fetch(
        `${serverBaseURL}/api/users/${userData.id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Token ${user.token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const updatedUserData = await response.json();
        setUserData(updatedUserData);
        setProfilePhoto(
          updatedUserData.profile_photo
            ? updatedUserData.profile_photo
            : require("assets/img/placeholder.jpg")
        );
        setProfileBanner(
          updatedUserData.profile_banner
            ? updatedUserData.profile_banner
            : require("assets/img/examples/city.jpg")
        );
        setOpenModal(false);
      } else {
        console.error("Failed to update user data:", await response.text());
      }
    }
  };
  return (
    <div className={classes.mobileViewContainer}>
      <Header
        color="transparent"
        brand="PCNA"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "info",
        }}
        {...rest}
      />
      <Parallax
        image={profileBanner}
        filter="dark"
        className={classes.parallax}
      />

      <Dialog
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="edit-profile-dialog-title"
      >
        <DialogTitle id="edit-profile-dialog-title">
          Edit Your Profile
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <TextField
                label="First Name"
                name="first_name"
                value={formValues.first_name}
                onChange={handleInputChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="last_name"
                value={formValues.last_name}
                onChange={handleInputChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                label="Bio"
                name="bio"
                value={formValues.bio}
                onChange={handleInputChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                label="Professional Designation"
                name="professional_designation"
                value={formValues.professional_designation}
                onChange={handleInputChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="profile_photo"
                name="profile_photo"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="profile_photo">
                <Button variant="outlined" component="span">
                  Upload Profile Photo
                </Button>
              </label>
            </GridItem>
            <GridItem xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="profile_banner"
                name="profile_banner"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="profile_banner">
                <Button variant="outlined" component="span">
                  Upload Profile Banner
                </Button>
              </label>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img src={profilePhoto} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  {userData && (
                    <>
                      <h1 className={classes.title}>
                        {userData.first_name} {userData.last_name}
                      </h1>
                      <h4>{userData.professional_designation}</h4>
                    </>
                  )}
                </div>
              </div>
              <div className={classes.follow}>
                <Tooltip
                  id="tooltip-top"
                  title="Follow this user"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    justIcon
                    round
                    color="primary"
                    className={classes.followButton}
                  >
                    <Add className={classes.followIcon} />
                  </Button>
                </Tooltip>
              </div>

              <div className={classes.follow}>
                <Tooltip
                  id="tooltip-top"
                  title="Edit your profile"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    justIcon
                    round
                    color="primary"
                    className={classes.followButton}
                    onClick={handleModalOpen} // Add this line
                  >
                    <Edit className={classes.followIcon} />{" "}
                    {/* Change 'Add' to 'Edit' */}
                  </Button>
                </Tooltip>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classNames(classes.description, classes.textCenter)}>
            {userData && <p>{userData.bio}</p>}
          </div>
          <ForumPostsProfile />
          {/* <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "User's Forum Posts",
                  tabIcon: Palette,
                  tabContent: <ForumPostsProfile />,
                },
              ]}
            />
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default ProfilePage;
