import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";

import Info from "components/Typography/Info";
import DOMPurify from "dompurify";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";

const useStyles = makeStyles(blogPostPageStyle);

function SinglePost({ ...rest }) {
  const classes = useStyles();
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const userCookie = Cookies.get("user");
  const userData = userCookie ? JSON.parse(userCookie) : null;
  const accessToken = userData ? userData.token : null;
  console.log(userData);

  console.log("Rendering SinglePost");
  console.log(accessToken);

  useEffect(() => {
    const fetchSinglePost = async (postId) => {
      try {
        const response = await axios.get(
          `https://pcnassociation.com/api/posts/${postId}/`
        );

        if (response.status < 200 || response.status >= 300) {
          throw new Error(`Request failed with status code ${response.status}`);
        }

        return response.data;
      } catch (error) {
        console.error(`Error fetching post with ID ${postId}:`, error);
      }
    };

    const fetchPost = async () => {
      const postData = await fetchSinglePost(postId);
      setPost(postData);
    };

    fetchPost();
  }, [postId, accessToken]);

  if (!post) {
    return <div>Loading...</div>;
  }
  function timeDifference(current, previous) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else {
      return new Date(previous).toLocaleDateString();
    }
  }
  const getCategoryDisplayName = (category) => {
    const categoryMapping = {
      internal_update: "Internal Update",
      news_update: "News Update",
    };
    return categoryMapping[category] || "Unknown";
  };
  const infoContentStyle = {
    margin: "8px",
  };
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="info"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      {/* <Parallax image={require("assets/img/bg5.jpg")} filter="dark"> */}
      <div className={classes.container}>
        <GridContainer justify="center" paddingTop="30">
          <GridItem md={8} className={classes.textCenter}>
            <h1 className={classes.title}>{post.title}</h1>
            <Info>
              <div style={infoContentStyle}>
                <h4 className={classes.cardCategory}>
                  {getCategoryDisplayName(post.category)}
                </h4>
              </div>
            </Info>
            <Info>
              <div style={infoContentStyle}>
                {/* Add user first name, last name, and post date */}
                <p>
                  Posted by {post.user.first_name} {post.user.last_name}{" "}
                  {timeDifference(new Date(), new Date(post.created_at))}
                </p>
              </div>
            </Info>
            <div
              className={`${classes.description} ${classes.inlineLink}`}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(post.body, {
                  ADD_ATTR: ["target", "rel"],
                  FORBID_TAGS: ["style"],
                }),
              }}
            ></div>
            <br />
            {post.image && (
              <img src={post.image} alt="Post" className={classes.img} />
            )}
          </GridItem>
        </GridContainer>
      </div>
      {/* </Parallax> */}
    </div>
  );
}

export default SinglePost;
