const clickableInputStyle = {
  clickableInput: {
    cursor: "pointer",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "12px 16px",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "transparent",
    transition:
      "border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, box-shadow 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
    "&:focus": {
      outline: "none",
      boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.25)",
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
};

export default clickableInputStyle;
