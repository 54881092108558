import {
  infoColor,
  whiteColor,
  main,
  mainRaised,
} from "assets/jss/material-kit-pro-react";
import {
  container,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const forumsProfileStyle = {
  container: {
    ...container,
    paddingTop: "50px", // Adjust this value to decrease the margin from the top
    justifyContent: "center",
    zIndex: "2",
    "@media (max-width: 767px)": {
      marginBottom: "200px", // Adjust this value for mobile view
      marginTop: "-200px",
    },
  },
  container2: {
    ...container,
    paddingTop: "20px", // Adjust this value to decrease the margin from the top

    justifyContent: "center",
    zIndex: "2",
    "@media (max-width: 767px)": {
      marginBottom: "200px", // Adjust this value for mobile view
      marginTop: "-200px",
    },
  },

  CustomMain: {
    padding: "20px", // Adjust this value to decrease the margin from the top
    marginTop: "150px",

    "@media (max-width: 767px)": {
      marginTop: "200px",
      padding: "15px",
    },
  },

  mobileButton: {
    "@media (max-width: 600px)": {
      fontSize: "0.7rem",
    },
  },

  title: {
    color: whiteColor,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  coloredShadow,
  cardTitle: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  mlAuto,
  mrAuto,
  description,
  blog: { paddingRight: "5px", paddingBottom: "15px" },
  main: {
    ...main,
    ...mainRaised,
  },
  cardCategory: {
    marginBottom: "0",

    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",

      lineHeight: "0",
    },
  },

  customH2Margin: {
    marginTop: "-70px", // Adjust this value to change the top margin
    marginBottom: "20px", // Adjust this value to change the bottom margin
    "@media (max-width: 767px)": {
      marginTop: "-10px", // Adjust this value to change the top margin
      marginBottom: "20px",
    },
  },
  customH3Margin: {
    marginTop: "-20px", // Adjust this value to change the top margin
    marginBottom: "90px", // Adjust this value to change the bottom margin
    "@media (max-width: 767px)": {
      marginTop: "60px", // Adjust this value to change the top margin
      paddingTop: "100px",
      marginBottom: "30px",
    },
  },

  textCenter: {
    textAlign: "center",
  },
  postContainer: {
    position: "relative",
    overflow: "hidden",
    border: "2px solid rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    transition: "border-color 0.3s",
    marginTop: "1px",
    marginBottom: "1px",
    marginRight: "9px",
    marginLeft: "9px",
    "@media (hover: hover)": {
      "&:hover": {
        borderColor: infoColor[0],
        marginRight: "10px",
        marginLeft: "10px",
      },
    },
  },
  mobileViewContainer: {
    maxWidth: "100%",
    overflowX: "hidden",
    "@media (min-width: 600px)": {
      maxWidth: "none",
      overflowX: "visible",
    },
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`, // Add this line
  },
  postOverlay: {
    position: "absolute",
    top: -10,
    left: -10,
    bottom: -10,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    opacity: 0,
    transition: "opacity 0.3s",
    "@media (hover: hover)": {
      "&:hover": {
        opacity: 0.3,
      },
    },
  },

  imgContainer: {
    overflow: "hidden",
    paddingRight: "40px",
    justifyContent: "center",
  },
  img: {
    maxHeight: "500px",
    objectFit: "contain",
  },
  description1: {
    ...description,
    lineHeight: "1.313rem",
  },
  author: {
    "& a": {
      color: grayColor[1],
      textDecoration: "none",
    },
  },
  card: {
    marginBottom: "80px",
    marginRight: "60px",
  },
  card4: {
    marginTop: "-3px",
    marginBottom: "-3px",
    marginLeft: "20px",
    marginRight: "60px",
    textAlign: "left",
    "@media (hover: hover)": {
      "&:hover $postOverlay": {
        opacity: 1,
      },
    },
  },
};

export default forumsProfileStyle;
