/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";

import UserPosts from "views/SectionsPage/Sections/UserPosts";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const HeadingTag = windowWidth <= 767 ? "h2" : "h1";

  const classes = useStyles();
  return (
    <div className={classes.mobileViewContainer}>
      <Header
        brand="PCNA"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "info",
        }}
      />

      <Parallax
        image={require("assets/img/NurseFront.png")}
        className={classes.parallax}
        filter="dark"
        mobileHeight="300px" // Adjust the height for mobile devices
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <HeadingTag>Philippine Canadian Nurses Association</HeadingTag>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionDescription />
        <Divider style={{ margin: "30px", padding: "2px" }} />
        <div className={classes.newsColor}>
          <h1>News and Current Events</h1>
        </div>
        <UserPosts />
      </div>

      <Footer />
    </div>
  );
}
