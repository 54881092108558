// src/components/ProtectedRoute.js
// ...

import React from "react";
import useAuth from "hooks/useAuth";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { authToken, loading } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return <div>Loading...</div>; // Show a loading indicator or any other placeholder
        }

        return authToken ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login-page" />
        );
      }}
    />
  );
};

export default ProtectedRoute;
