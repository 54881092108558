import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted.js";
import Footer from "components/Footer/Footer";
import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import city from "assets/img/examples/city.jpg";
import Lucy1 from "assets/img/Lucy1.jpg";
import Edgar1 from "assets/img/Edgar1.jpg";

import Glaze1 from "assets/img/Glaze1.png";
import Leo1 from "assets/img/Leo1.png";
import Aniela2 from "assets/img/Aniela2.jpg";
import Mila1 from "assets/img/Mila1.png";
import Ivon1 from "assets/img/Ivon1.png";
import Angela1 from "assets/img/Angela1.png";
import Ferdi1 from "assets/img/Ferdi1.png";
import Jay2 from "assets/img/Jay2.png";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams() {
  const classes = useStyles();
  return (
    <div className={classes.mobileViewContainer}>
      <Header
        color="info"
        brand="Material Kit PRO React"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
      />
      <div
        className={classes.team + " " + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={12}
              margin="40px"
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Meet Our Executives</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Lucy1} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>
                        Lucy O. Reyes RN, MN
                      </h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>President</h4>
                      </Muted>
                      <p className={classes.description}>
                        BScN: Far Eastern University MN: University of Calgary
                        Certificate in Healthcare Ethics: University of
                        Washington Certificate in Health Informatics: University
                        of Victoria Currently Research Coordinator University of
                        Calgary 2021 FWN Global Award Recipient: Health
                        Innovation and Thought Leader
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Edgar1} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>
                        Edgar Trinidad RN, BScN
                      </h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>Vice-President</h4>
                      </Muted>
                      <p className={classes.description}>
                        BScN: University of Santo Tomas Critical Care
                        Certificate: Humber College Neuroscience Certificates:
                        George Brown College Clinical Instructor Humber College
                        CCU Course Case Manager AHS Integrated Homecare
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            {/* <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Tes1} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>
                        Maria Teresa Bañaga-Ala RN, BScN
                      </h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>
                          Executive Director
                        </h4>
                      </Muted>
                      <p className={classes.description}>
                        BScN: Bicol University Cum Laude Nursing Refresher
                        Program: Grant MacEwan University Gerontology
                        Certificate: Royal Alexandria Edmonton AB Nurse
                        Clinician/Case Manager Kidney Care Clinic AHS
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem> */}
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Glaze1} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>
                        Glaze De Lara Idul LPN
                      </h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>
                          Executive Secretary
                        </h4>
                      </Muted>
                      <p className={classes.description}>
                        BScN: Dr. Carlos S. Lanting College of Nursing Practical
                        Nursing Columbia College LPN Unit Medical Unit FMC AHS
                        LPN Integrated Home Care
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Leo1} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>
                        Leonida Relleve RN, BScN
                      </h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>
                          Policy and Development
                        </h4>
                      </Muted>
                      <p className={classes.description}>
                        BScN: St. Jude College of Nursing Master of Arts in
                        Nursing: St. Jude University Doctor of Educational
                        Management: Polytechnic University Philippines RN
                        Transition Unit AHS
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Aniela2} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>
                        Dr. Aniela dela Cruz
                      </h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>
                          Academic Liaison/Advisor
                        </h4>
                      </Muted>
                      <p className={classes.description}>
                        BN: University of Calgary MSc: University of Alberta
                        PhD: University of Alberta Associate Professor Faculty
                        of Nursing University of Calgary
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Mila1} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Mila Prout RN, BN</h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>
                          Education and Research
                        </h4>
                      </Muted>
                      <p className={classes.description}>
                        BN: Athabasca University Previous Clinical Instructor
                        Conjoint Nursing Program MRU and U of C Previous Nurse
                        Clinician Carewest RCTP Nurse Educator Operator
                        Supportive Living Homes
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Ivon1} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>
                        Ivonnie Joy G. Abes RN, BScN
                      </h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>
                          Membership/Human Resources
                        </h4>
                      </Muted>
                      <p className={classes.description}>
                        BScN: Pamantasan ng Lungsod ng Maynila (PLM)
                        Certificates in Organizational Development, Project
                        Management: Medecins Sans Frontieres (MSF International
                        - HKG) National Council of Social Development (Phil.
                        NCSD) AHS Neuro Rehabilitation
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Angela1} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>
                        Angela Botengan BScN, RN, CGN (c), MN
                      </h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>
                          Association Membership/Human Resources
                        </h4>
                      </Muted>
                      <p className={classes.description}>
                        BScN: Baguio Central University MN: University of
                        Calgary FMC Nurse Clinician, Medical-Transition Units -
                        AHS
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Ferdi1} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Ferdinand Zantua</h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>
                          Director of Community Relations
                        </h4>
                      </Muted>
                      <p className={classes.description}>
                        Doctor of Dental Medicine: Centro Escolar University
                        Dentist: Zantua Dental Clinic Entrepreneur Health
                        Education Provider Frontliner AHS Renal Dialysis
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img src={Jay2} alt="..." />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Jay Zapata</h4>
                      <Muted>
                        <h4 className={classes.cardCategory}>
                          Communication and Advocacy
                        </h4>
                      </Muted>
                      <p className={classes.description}>
                        BA Major in Industrial Psychology: Holy Angel University
                        Forensic Psychology Certificate: National CEnter for
                        Mental Health, Philippines Community Broker: Action
                        Dignity Government of Alberta Employee
                      </p>
                    </CardBody>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
