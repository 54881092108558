import React from "react";
import { Typography } from "@material-ui/core";

export const TermsAndConditionsContent = () => {
  return (
    <>
      <Typography>Last Updated: April 24, 2023</Typography>
      <Typography paragraph>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions")
        carefully before using the Philippine Canadian Nurses Association
        website and mobile application (the "Service") operated by Philippine
        Canadian Nurses Association ("us", "we", or "our").
        {/*...*/}
      </Typography>

      {/* Add more sections as needed */}

      <Typography variant="h6" component="h3" gutterBottom>
        1. Registration
      </Typography>
      <Typography paragraph>
        To use our Service, you must provide certain information, including your
        first name, last name, professional designation, email address, and
        password. You agree to provide accurate, current, and complete
        information during the registration process and to update such
        information to keep it accurate, current, and complete. We reserve the
        right to suspend or terminate your account if any information provided
        during the registration process or thereafter proves to be inaccurate,
        not current, or incomplete.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        2. Account Security
      </Typography>
      <Typography paragraph>
        You are responsible for safeguarding the password that you use to access
        the Service and for any activities or actions under your password. You
        agree not to disclose your password to any third party. You must notify
        us immediately upon becoming aware of any breach of security or
        unauthorized use of your account.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        3. User Content
      </Typography>
      <Typography paragraph>
        By posting or sharing content on our Service, you represent and warrant
        that you have the right, power, and authority to post that content and
        grant the necessary licenses and permissions for the use of the content
        by the Service and other users.
        <Typography paragraph>
          You retain any and all of your rights to any content you submit, post,
          or display on or through the Service, and you are responsible for
          protecting those rights. By submitting, posting, or displaying content
          on or through the Service, you grant us a worldwide, non-exclusive,
          royalty-free license (with the right to sublicense) to use, copy,
          reproduce, process, adapt, modify, publish, transmit, display, and
          distribute such content in any and all media or distribution methods
          (now known or later developed).
        </Typography>
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        4. Acceptable Use
      </Typography>
      <Typography paragraph>
        You agree not to use the Service for any illegal or unauthorized
        purpose. You agree to comply with all laws, rules, and regulations (for
        example, federal, state, local, and provincial) applicable to your use
        of the Service and your content, including but not limited to, copyright
        laws.
        <Typography paragraph>
          You are solely responsible for your conduct and any data, text, files,
          information, images, graphics, photos, profiles, audio, and video
          clips, links, and other content ("Materials") that you submit, post,
          and display on the Service.
        </Typography>
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        5. Prohibited Content and Activities
      </Typography>
      <Typography component="div" paragraph>
        <Typography component="span" display="inline">
          {"\u2022 "}
        </Typography>
        <Typography component="span" display="inline">
          Posting or sharing content that is harmful, abusive, unlawful,
          threatening, harassing, blasphemous, defamatory, obscene,
          pornographic, pedophilic, libelous, invasive of another's privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner.
        </Typography>
      </Typography>
      <Typography component="div" paragraph>
        <Typography component="span" display="inline">
          {"\u2022 "}
        </Typography>
        <Typography component="span" display="inline">
          Posting or sharing content that infringes any patent, trademark, trade
          secret, copyright, or other proprietary rights of any party.
        </Typography>
      </Typography>
      <Typography component="div" paragraph>
        <Typography component="span" display="inline">
          {"\u2022 "}
        </Typography>
        <Typography component="span" display="inline">
          Impersonating another person or entity or falsely stating or otherwise
          misrepresenting your affiliation with a person or entity.
        </Typography>
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        6. Limitation of Liability
      </Typography>
      <Typography paragraph>
        In no event shall Philippine Canadian Nurses Association, nor its
        directors, employees, partners, agents, suppliers, or affiliates, be
        liable for any indirect, incidental, special, consequential, or punitive
        damages, including without limitation, loss of profits, data, use,
        goodwill, or other intangible losses, resulting from (i) your access to
        or use of or inability to access or use the Service; (ii) any conduct or
        content of any third party on the Service; (iii) any content obtained
        from the Service; and (iv) unauthorized access, use, or alteration of
        your transmissions or content, whether based on warranty, contract, tort
        (including negligence), or any other legal theory, whether or not we
        have been informed of the possibility of such damage, and even if a
        remedy set forth herein is found to have failed of its essential
        purpose.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        7. Indemnification
      </Typography>
      <Typography paragraph>
        You agree to defend, indemnify, and hold harmless Philippine Canadian
        Nurses Association and its licensee and licensors, and their employees,
        contractors, agents, officers, and directors, from and against any and
        all claims, damages, obligations, losses, liabilities, costs or debt,
        and expenses (including but not limited to attorney's fees), resulting
        from or arising out of a) your use and access of the Service, by you or
        any person using your account and password; b) a breach of these Terms,
        or c) content posted on the Service.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        8. Disclaimer
      </Typography>
      <Typography paragraph>
        Your use of the Service is at your sole risk. The Service is provided on
        an "AS IS" and "AS AVAILABLE" basis. The Service is provided without
        warranties of any kind, whether express or implied, including, but not
        limited to, implied warranties of merchantability, fitness for a
        particular purpose, non-infringement, or course of performance.
      </Typography>
      <Typography paragraph>
        Philippine Canadian Nurses Association, its subsidiaries, affiliates,
        and its licensors do not warrant that a) the Service will function
        uninterrupted, secure, or available at any particular time or location;
        b) any errors or defects will be corrected; c) the Service is free of
        viruses or other harmful components; or d) the results of using the
        Service will meet your requirements.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        9. Changes
      </Typography>
      <Typography paragraph>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will try to provide at
        least 30 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
        By continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you must stop using the Service.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        10. Governing Law
      </Typography>
      <Typography paragraph>
        These Terms shall be governed and construed in accordance with the laws
        of Canada, without regard to its conflict of law provisions. Our failure
        to enforce any right or provision of these Terms will not be considered
        a waiver of those rights. If any provision of these Terms is held to be
        invalid or unenforceable by a court, the remaining provisions of these
        Terms will remain in effect. These Terms constitute the entire agreement
        between us regarding our Service, and supersede and replace any prior
        agreements we might have had between us regarding the Service.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        11. Contact Us
      </Typography>
      <Typography paragraph>
        If you have any questions about these Terms, please contact us at
        membership@pcnassociation.ca.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        12. Termination
      </Typography>
      <Typography paragraph>
        We may terminate or suspend your account and access to the Service
        immediately, without prior notice or liability, for any reason
        whatsoever, including, without limitation, if you breach the Terms. Upon
        termination, your right to use the Service will immediately cease. If
        you wish to terminate your account, you may simply discontinue using the
        Service.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        13. Intellectual Property
      </Typography>
      <Typography paragraph>
        The Service and its original content, features, and functionality are
        and will remain the exclusive property of Philippine Canadian Nurses
        Association and its licensors. The Service is protected by copyright,
        trademark, and other laws of both the Canada and foreign countries. Our
        trademarks and trade dress may not be used in connection with any
        product or service without the prior written consent of Philippine
        Canadian Nurses Association.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        14. Third-Party Links
      </Typography>
      <Typography paragraph>
        Our Service may contain links to third-party websites or services that
        are not owned or controlled by Philippine Canadian Nurses Association.
        Philippine Canadian Nurses Association has no control over and assumes
        no responsibility for the content, privacy policies, or practices of any
        third-party websites or services. We do not warrant the offerings of any
        of these entities/individuals or their websites.
      </Typography>
      <Typography paragraph>
        You acknowledge and agree that Philippine Canadian Nurses Association
        shall not be responsible or liable, directly or indirectly, for any
        damage or loss caused or alleged to be caused by or in connection with
        use of or reliance on any such content, goods, or services available on
        or through any such third-party websites or services. We strongly advise
        you to read the terms and conditions and privacy policies of any
        third-party websites or services that you visit.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        15. International Users
      </Typography>
      <Typography paragraph>
        The Service is controlled, operated, and administered by Philippine
        Canadian Nurses Association from our offices within the Canada. If you
        access the Service from a location outside the Canada, you are
        responsible for compliance with all local laws. You agree that you will
        not use the Philippine Canadian Nurses Association content accessed
        through the Service in any country or in any manner prohibited by any
        applicable laws, restrictions, or regulations.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        16. Entire Agreement
      </Typography>
      <Typography paragraph>
        These Terms, together with any other legal notices and agreements
        published by Philippine Canadian Nurses Association on the Service,
        shall constitute the entire agreement between you and Philippine
        Canadian Nurses Association concerning the Service, and supersede all
        prior understandings, negotiations, or agreements, whether written or
        oral, between you and Philippine Canadian Nurses Association with
        respect to the subject matter hereof.
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        17. Changes to Terms
      </Typography>
      <Typography paragraph>
        We reserve the right, in our sole discretion, to change the Terms under
        which the Service is offered. The most current version of the Terms will
        supersede all previous versions. We encourage you to periodically review
        the Terms to stay informed of our updates.
      </Typography>
    </>
  );
};
