/*eslint-disable*/
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import classNames from "classnames";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { userState } from "state";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import Cookies from "js-cookie";
import image from "assets/img/LoginRegister.png";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import useAuth from "hooks/useAuth";

const useStyles = makeStyles(signupPageStyle);

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [
    resetPasswordInputModalOpen,
    setResetPasswordInputModalOpen,
  ] = useState(false);
  const [uidb64, setUidb64] = useState(null);
  const [token, setToken] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false); // Add this line
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [newPasswordConfirmVisible, setNewPasswordConfirmVisible] = useState(
    false
  ); // Add this line
  const [user, setUser] = useRecoilState(userState);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { login } = useAuth();

  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);

  const displaySnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 5000);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // New function for toggling visibility
  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleNewPasswordConfirmVisibility = () => {
    setNewPasswordConfirmVisible(!newPasswordConfirmVisible);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://pcnassociation.com/api/users/api-token-auth/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: email,
            password: password,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Handle successful authentication here, e.g., storing the user's token and redirecting to the dashboard.
        console.log("Authentication successful:", data);

        // Save the user object to localStorage
        const user = {
          user_id: data.user_id, // Include the user_id in the user object
          email: email,
          token: data.token, // Assuming the token is part of the response
          first_name: data.first_name,
          last_name: data.last_name,
          alma_mater: data.alma_mater,
          profile_photo: data.profile_photo,
          profile_banner: data.profile_banner,
          bio: data.bio,

          // any other user data you want to store
        };
        console.log("User:", user);
        Cookies.set("user", JSON.stringify(user), { expires: 7 }); // The cookie will expire in 7 days

        // Log in the user using the token
        login(
          data.token,
          data.user_id,
          data.first_name,
          data.last_name,
          data.alma_mater,
          data.profile_photo,
          data.profile_banner,
          data.bio
        );

        // Redirect to the landing page.
        history.push("/news-updates");
      } else {
        // Handle unsuccessful authentication here, e.g., showing an error message to the user.
        const errorData = await response.json();
        setErrorMessage("Invalid email or password. Please try again.");
        const errorMessages = Object.entries(errorData)
          .map(([key, value]) => `${key}: ${value}`)
          .join(", ");
        console.error("Authentication failed:", errorData);
        displaySnackbar(`Authentication Failed: ${errorMessages}`);
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      setErrorMessage(
        "An error occurred during authentication. Please try again."
      );
      displaySnackbar(`Error during authentication: ${error.message}`);
    }
  };

  // New function for password reset
  const resetPassword = async () => {
    console.log("Sending password reset request for email:", email);
    try {
      const response = await axios.post(
        "https://pcnassociation.com/api/users/password-reset/",
        { email }
      );
      console.log("Password reset response:", response);
      alert(response.data.message);
      setResetPasswordDialogOpen(false);
    } catch (error) {
      console.error("Error during password reset:", error);
      if (error.response) {
        alert(error.response.data.error);
      } else if (error.request) {
        console.error("Request made but no response received:", error.request);
        alert("An error occurred while sending the request");
      } else {
        console.error(
          "Something happened in setting up the request:",
          error.message
        );
        alert("An error occurred");
      }
    }
  };

  useEffect(() => {
    if (queryParams.get("resetPassword") === "true") {
      const uidb64 = queryParams.get("uidb64");
      const token = queryParams.get("token");

      // Save uidb64 and token to state
      setUidb64(uidb64);
      setToken(token);

      // Open password reset input modal
      setResetPasswordInputModalOpen(true);
    }
  }, [history]);

  const submitNewPassword = async () => {
    try {
      // Send a request to your password_reset_confirm view
      const response = await axios.post(
        `https://pcnassociation.com/api/users/password-reset-confirm/${uidb64}/${token}/`,
        { new_password: newPassword, new_password_confirm: newPasswordConfirm }
      );

      // Handle the response here
      alert(response.data.message);
      setResetPasswordInputModalOpen(false);
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      } else {
        alert("An error occurred while sending the request.");
      }
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.mobileViewContainer}>
      <Header
        absolute
        color="transparent"
        brand="PCNA"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer
            className={classNames(
              classes.gridContainer,
              classes.mobileGridContainer
            )}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <GridItem>
              <Card className={classes.cardSignup}>
                <h2 className={classes.cardTitle}>Login</h2>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem>
                      <form className={classes.form}>
                        <CustomInput
                          id="email"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Email...",
                            type: "email",
                            value: email,
                            onChange: (event) => setEmail(event.target.value),
                            startAdornment: (
                              <InputAdornment position="start">
                                <Email className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          id="password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Password...",
                            type: passwordVisible ? "text" : "password",
                            value: password,
                            onChange: (event) =>
                              setPassword(event.target.value),
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon className={classes.inputIconsColor}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon
                                  className={classes.inputIconsColor}
                                  onClick={togglePasswordVisibility}
                                >
                                  {passwordVisible
                                    ? "visibility"
                                    : "visibility_off"}
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={rememberMe}
                              onChange={(event) =>
                                setRememberMe(event.target.checked)
                              }
                              color="primary"
                            />
                          }
                          label="Remember me"
                        /> */}

                        <div className={classes.textCenter}>
                          <Button
                            simple
                            color="primary"
                            size="lg"
                            onClick={handleSubmit}
                          >
                            Get started
                          </Button>
                          {/* Update the button to open the dialog */}
                          <Button
                            simple
                            color="primary"
                            size="lg"
                            onClick={() => setResetPasswordDialogOpen(true)}
                          >
                            Reset Password
                          </Button>

                          {/* Add the reset password dialog */}
                          <Dialog
                            open={resetPasswordDialogOpen}
                            onClose={() => setResetPasswordDialogOpen(false)}
                          >
                            <DialogContent>
                              <CustomInput
                                id="reset-password-email"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder: "Email...",
                                  type: "email",
                                  value: email,
                                  onChange: (event) =>
                                    setEmail(event.target.value),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Email
                                        className={classes.inputIconsColor}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={resetPassword} color="primary">
                                Send Reset Email
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Dialog
                            open={resetPasswordInputModalOpen}
                            onClose={() => {}}
                            disableBackdropClick={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogContent>
                              <CustomInput
                                labelText="New Password"
                                id="new-password"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: newPasswordVisible
                                    ? "text"
                                    : "password",
                                  value: newPassword,
                                  onChange: (e) =>
                                    setNewPassword(e.target.value),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon className={classes.inputIconsColor}>
                                        lock_outline
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Icon
                                        className={classes.inputIconsColor}
                                        onClick={toggleNewPasswordVisibility}
                                      >
                                        {newPasswordVisible
                                          ? "visibility"
                                          : "visibility_off"}
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <CustomInput
                                labelText="Confirm New Password"
                                id="confirm-new-password"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: newPasswordConfirmVisible
                                    ? "text"
                                    : "password",
                                  value: newPasswordConfirm,
                                  onChange: (e) =>
                                    setNewPasswordConfirm(e.target.value),
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Icon className={classes.inputIconsColor}>
                                        lock_outline
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Icon
                                        className={classes.inputIconsColor}
                                        onClick={
                                          toggleNewPasswordConfirmVisibility
                                        }
                                      >
                                        {newPasswordConfirmVisible
                                          ? "visibility"
                                          : "visibility_off"}
                                      </Icon>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => {
                                  submitNewPassword(
                                    newPassword,
                                    newPasswordConfirm
                                  );
                                }}
                                color="primary"
                              >
                                Submit
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </form>
                      {snackbarOpen && (
                        <SnackbarContent
                          message={
                            <span>
                              <b>Error:</b> {snackbarMessage}
                            </span>
                          }
                          close
                          color="danger"
                          icon="info_outline"
                          style={{ paddingTop: "100px" }}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
