import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={`${classes.container} ${classes.noPaddingMobile}`}>
        <GridContainer justify="center">
          <GridItem md={8} sm={8} xs={9}>
            <h4 className={`${classes.description} ${classes.textCenter}`}>
              We are a vibrant community of Internationally Educated Nurses
              (IENs) - including RNs, RONs, NPs, LPNs, and Nursing Aides -
              trained outside of Canada, as well as Canadian Educated Nurses of
              Filipino Ancestry. United, we strive to elevate our presence,
              seamlessly integrate into Canadian Nursing, and contribute
              significantly to the health and well-being of Canadians.
            </h4>
          </GridItem>
          <GridItem
            md={8}
            sm={8}
            xs={9}
            className={classes.textCenter}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button color="primary" variant="contained" href="https://forms.gle/WbPeCMnsW2ptxKA17" target="_blank">
              Become a Member Today!
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
