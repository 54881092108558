import {
  primaryColor,
  grayColor,
  whiteColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

import customCheckboxRadioSwitchStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";

const signupPageStyle = {
  container: {
    zIndex: "2",
    position: "relative",
    paddingTop: "20vh",
    color: whiteColor,
    display: "flex", // Add this
    justifyContent: "center", // Add this
    alignItems: "center", // Add this
    minHeight: "100vh", // Add this
    "@media (max-width: 575px)": {
      marginLeft: "15px",
      marginRight: "15px",
    },
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    justifyContent: "center",
    maxWidth: "100%", // Add this line to make sure the container doesn't exceed the viewport width
    margin: "0 auto", // Add this line to center the container
    "@media (min-width: 768px)": {
      // Add this media query for desktop view
      maxWidth: "calc(100% - 48px)", // Replace this value with the desired width for desktop view
    },
  },

  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    width: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)",
    },
    "&:after": {
      background:
        "linear-gradient(60deg,rgba(" +
        hexToRgb(primaryColor[4]) +
        ",.56),rgba(" +
        hexToRgb(primaryColor[5]) +
        ",.95))",
    },
    "&:overlay": {
      background: "rgba(128, 128, 128, 0.5)", // Translucent gray overlay
    },
    "&:before,&:after,&:overlay": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      alignItems: "center",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
  },

  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2);",
    marginBottom: "100px",
    padding: "40px 0px",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  cardTitle: {
    textDecoration: "none",
    textAlign: "center !important",
    marginBottom: "0.75rem",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },

  ...customCheckboxRadioSwitchStyle,
  socials: {
    marginTop: "0",
    position: "absolute",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
  },
  mobileViewContainer: {
    maxWidth: "100%",
    overflowX: "hidden",
    "@media (min-width: 600px)": {
      maxWidth: "none", // Update this line
      overflowX: "visible",
    },
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`, // Add this line
  },

  textCenter: {
    textAlign: "center",
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  inputAdornmentIcon: {
    color: grayColor[13],
  },
  inputWrapper: {
    position: "relative",
    "@media (max-width: 600px)": {
      paddingTop: "20vh",
    },
  },
  form: {
    margin: "0",
  },
  infoArea: {
    padding: "0px 0px 20px !important",
  },
  mobileGridContainer: {
    "@media (max-width: 600px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important",
    },
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important",
    },
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
};

export default signupPageStyle;
