import React from "react";
// react component for creating beautiful carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
// import CardFooter from "components/Card/CardFooter.js";
import MembershipB1 from "assets/img/membership/MembershipB1.png";
import MembershipB2 from "assets/img/membership/MembershipB2.png";
import MembershipB3 from "assets/img/membership/MembershipB3.png";
import MembershipB4 from "assets/img/membership/MembershipB4.png";
import MembershipB5 from "assets/img/membership/MembershipB5.png";
import MembershipB6 from "assets/img/membership/MembershipB6.png";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";
const useStyles = makeStyles(testimonialsStyle);

export default function SectionTestimonials({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div className="cd-section" {...rest}>
      {/* Testimonials 2 START */}
      <div
        className={
          classes.testimonials +
          " " +
          classes.sectionDark +
          " " +
          classes.testimonial2
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Carousel {...settings}>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <img src={MembershipB1} alt="..." />
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <img src={MembershipB2} alt="..." />
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <img src={MembershipB3} alt="..." />
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <img src={MembershipB4} alt="..." />
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <img src={MembershipB5} alt="..." />
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <img src={MembershipB6} alt="..." />
                  </Card>
                </div>
              </Carousel>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 2 END */}
    </div>
  );
}
