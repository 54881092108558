import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import forumsProfileStyle from "assets/jss/material-kit-pro-react/views/forumSectionStyle/forumsProfileStyle.js";
import Info from "components/Typography/Info";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AvatarWithInput from "components/AvatarWithInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import DOMPurify from "dompurify";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles(forumsProfileStyle);

const API_BASE_URL = "https://pcnassociation.com";

function ForumPosts({ staticContext, ...rest }) {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [imageUploadKey, setImageUploadKey] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [tagCategory, setTagCategory] = useState("");
  const [editingPostId, setEditingPostId] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [editBody, setEditBody] = useState("");
  const [editImage, setEditImage] = useState(null);
  const [editImageUploadKey, setEditImageUploadKey] = useState(0);
  const [editTagCategory, setEditTagCategory] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const userCookie = Cookies.get("user");
  const userData = userCookie ? JSON.parse(userCookie) : null;
  const currentUserId = userData.user_id;
  const accessToken = userData ? userData.token : null;

  const classes = useStyles();
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "header",
    "list",
    "bullet",
    "indent",
    "script",
    "direction",
    "size",
    "color",
    "background",
    "font",
    "align",
    "link",
    "image",
    "video",
  ];
  const fetchUserPosts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/forum_posts/`, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching user posts:", error);
    }
  };
  const isFirstRender = useRef(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUserId && accessToken) {
        const response = await axios.get(
          `https://pcnassociation.com/api/users/${currentUserId}/`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          const userData = response.data;
          setUserProfile(userData);
        } else {
          console.error("Failed to fetch user data:", response.statusText);
        }
      }
    };

    if (isFirstRender.current) {
      // Fetch user data on the first render (component mount)
      fetchUserData();
      isFirstRender.current = false;
    }
  }, [currentUserId, accessToken]);

  const sortPostsByDate = useCallback(
    (posts) => {
      try {
        const sortedPosts = posts.sort((a, b) => {
          const dateA = new Date(a.updated_at);
          const dateB = new Date(b.updated_at);

          if (sortOrder === "newest") {
            return dateB - dateA;
          } else {
            return dateA - dateB;
          }
        });

        return sortedPosts;
      } catch (error) {
        console.error("Error sorting user posts:", error);
      }
    },
    [sortOrder] // sortOrder is added as a dependency
  );

  useEffect(() => {
    const fetchAllPosts = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/forum_posts/?expand=tag_category`,
          {
            headers: {
              Authorization: `Token ${accessToken}`,
            },
          }
        );
        const allPosts = response.data;
        setUserPosts(allPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    if (accessToken) {
      fetchAllPosts();
    }
  }, [userPosts, sortOrder, sortPostsByDate, accessToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!accessToken || userProfile.is_staff === "false") {
      console.error("Access token not found");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("tag_category", tagCategory); // Add this line to include the tag_category

      if (image) {
        formData.append("image", image);
      }

      const response = await axios.post(
        `${API_BASE_URL}/api/forum_posts/`,
        formData,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      // Log a message when the post is successful
      console.log("Post submitted successfully");
      showSnackbar("Post submitted successfully");
      setTitle("");
      setBody("");
      setTagCategory(""); // Reset the tagCategory state
      setImage(null);
      setImageUploadKey(imageUploadKey + 1); // Reset the ImageUpload component

      // Fetch the updated list of user posts after successful submission
      const updatedPosts = await fetchUserPosts();
      // Close the modal after successful submission
      handleModalClose();
      setUserPosts(updatedPosts);
    } catch (error) {
      console.error("Error submitting post:", error);
      showSnackbar("Error submitting post");
    }
  };

  const handleEditPost = async (postId) => {
    try {
      const formData = new FormData();
      formData.append("title", editTitle);
      formData.append("body", editBody);
      formData.append("tag_category", editTagCategory);

      if (editImage) {
        formData.append("image", editImage);
      }

      const response = await axios.put(
        `${API_BASE_URL}/api/forum_posts/${postId}/`,
        formData,
        {
          headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Request failed with status code ${response.status}`);
      }

      // Log a message when the post is successfully edited
      console.log("Post edited successfully");
      showSnackbar("Post edited successfully");
      setEditingPostId(null);
      setEditTitle("");
      setEditBody("");
      setEditTagCategory("");
      setEditImage(null);
      setEditImageUploadKey(editImageUploadKey + 1);

      // Fetch the updated list of user posts after successful editing
      const updatedPosts = await fetchUserPosts();

      setUserPosts(updatedPosts);
    } catch (error) {
      console.error("Error editing post:", error);
      showSnackbar("Error editing post");
    }
  };

  const handleOpenEditForm = (postId, title, body, tagCategory) => {
    setEditingPostId(postId);
    setEditTitle(title);
    setEditBody(body);
    setEditTagCategory(tagCategory);
  };

  const handleImageChange = (file) => {
    setImage(file);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleModalClose = (event, reason) => {
    if (reason === "backdropClick") {
      // Show your warning here
      console.warn("You clicked away from the modal!");
    }
    setOpenModal(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarVisible(true);
    setTimeout(() => {
      hideSnackbar();
    }, 6000);
  };

  const hideSnackbar = () => {
    setSnackbarVisible(false);
  };

  const handleDeletePost = async (postId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/forum_posts/${postId}/`, {
        headers: {
          Authorization: `Token ${accessToken}`,
        },
      });

      // Filter out the deleted post and update the state
      const updatedPosts = userPosts.filter((post) => post.id !== postId);
      setUserPosts(updatedPosts);
    } catch (error) {
      console.error(`Error deleting post with ID ${postId}:`, error);
    }
  };

  function timeDifference(current, previous) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " seconds ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else {
      return new Date(previous).toLocaleDateString();
    }
  }

  const infoContentStyle = {
    marginTop: "10px",
    marginLeft: "4px",
  };

  function addTargetBlank(node) {
    if (node.tagName && node.tagName.toLowerCase() === "a") {
      node.setAttribute("target", "_blank");
      node.setAttribute("rel", "noopener noreferrer");
    }
  }

  return (
    <div className={classes.CustomMain} {...rest}>
      {/* Add form for user input */}
      <div>
        <div className={classes.container}>
          {snackbarVisible && (
            <SnackbarContent
              style={{
                position: "fixed",
                bottom: "20px",
                left: "20px",
                zIndex: 1500,
              }}
              message={
                <span>
                  <b>{snackbarMessage}</b>
                </span>
              }
              close
              color="success"
              icon={Check}
            />
          )}
          <GridContainer justify="center" direction="row">
            <GridItem xs={10} sm={12} md={8}>
              {userProfile && (
                <AvatarWithInput
                  avatarUrl={userProfile && userProfile.profile_photo}
                  onClick={handleOpenModal}
                  placeholder="Create Post..."
                />
              )}
            </GridItem>
          </GridContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomDropdown
              noLiPadding
              navDropdown
              hoverColor="info"
              buttonText="Update Date"
              buttonProps={{
                className: classes.navLink,
                color: "transparent",
              }}
              dropdownList={[
                <div
                  onClick={() => setSortOrder("newest")}
                  className={classes.dropdownLink}
                >
                  Newest
                </div>,
                <div
                  onClick={() => setSortOrder("oldest")}
                  className={classes.dropdownLink}
                >
                  Oldest
                </div>,
              ]}
            />
          </div>
        </div>

        {/* Create post modal */}
        <Dialog
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="create-post-modal-title"
          aria-describedby="create-post-modal-description"
          maxHeight="300px"
          overflow="hidden"
          PaperProps={{
            style: { overflowY: "hidden" },
          }}
        >
          <form
            onSubmit={handleSubmit}
            className={classes.form}
            justify="center"
          >
            <DialogContent>
              <h2>Create Post</h2>
              <CardBody signup>
                <CustomInput
                  id="title"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "Title...",
                    type: "text",
                    value: title,
                    onChange: (e) => setTitle(e.target.value),
                  }}
                />
                <CustomInput
                  id="body"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "Body...",
                    multiline: true,
                    value: body,
                    onChange: (e) => setBody(e.target.value),
                  }}
                />
                <CustomInput
                  id="tag_category"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "Topic...",
                    multiline: true,
                    value: tagCategory,
                    onChange: (e) => setTagCategory(e.target.value),
                  }}
                />
                <ImageUpload
                  key={imageUploadKey}
                  addButtonProps={{
                    round: true,
                  }}
                  changeButtonProps={{
                    round: true,
                  }}
                  removeButtonProps={{
                    round: true,
                    color: "danger",
                  }}
                  onChange={(file) => handleImageChange(file)}
                />
              </CardBody>
              <CardBody>
                <Button color="primary" size="large" type="submit">
                  Submit
                </Button>
              </CardBody>
            </DialogContent>
          </form>
        </Dialog>

        {/* Render submitted Forum posts */}
        <div className={classes.blog}>
          <div className={classes.container2}>
            {userData && (
              <GridContainer>
                {sortPostsByDate(userPosts || []).map((post, index) => (
                  <GridItem
                    key={index}
                    xs={12}
                    sm={8}
                    md={8}
                    className={classes.mlAuto + " " + classes.mrAuto}
                  >
                    <div className={classes.postContainer}>
                      <Card plain blog className={classes.card4}>
                        <CardHeader
                          image
                          plain
                          className={classes.imgContainer}
                        >
                          <Info>
                            <div style={infoContentStyle}>
                              {/* Add user first name, last name, and post date */}
                              <p>
                                Posted by {post.user.first_name}{" "}
                                {post.user.last_name}{" "}
                                {timeDifference(
                                  new Date(),
                                  new Date(post.created_at)
                                )}
                              </p>
                            </div>
                            <div style={infoContentStyle}>
                              <h3 style={infoContentStyle}>
                                {post.tag_category}
                              </h3>
                            </div>
                          </Info>
                          <h3 className={classes.cardTitle}>{post.title}</h3>
                          <div
                            className={`${classes.description} ${classes.inlineLink}`}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(post.body, {
                                ADD_ATTR: ["target", "rel"],
                                FORBID_TAGS: ["style"],
                                TRANSFORM_NODE: addTargetBlank,
                              }),
                            }}
                          ></div>
                          <a
                            href="#pablito"
                            onClick={(e) => e.preventDefault()}
                          >
                            {post.image && (
                              <img
                                src={post.image}
                                alt="..."
                                className={classes.img}
                              />
                            )}
                          </a>
                        </CardHeader>
                        <CardBody plain>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              component={Link}
                              to={`/forum-posts/${post.id}`}
                              className={`${classes.roundedButton} ${classes.mobileButton}`}
                              color="primary"
                            >
                              Read More
                            </Button>

                            {currentUserId === post.user.id && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginRight: "40px",
                                }}
                              >
                                <Button
                                  className={`${classes.roundedButton} ${classes.mobileButton}`}
                                  color="primary"
                                  onClick={() => handleDeletePost(post.id)}
                                >
                                  Delete
                                </Button>
                                <Button
                                  className={`${classes.roundedButton} ${classes.mobileButton}`}
                                  color="primary"
                                  onClick={() =>
                                    handleOpenEditForm(
                                      post.id,
                                      post.title,
                                      post.body,
                                      post.tag_category
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                              </div>
                            )}
                          </div>
                          {editingPostId === post.id && (
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleEditPost(post.id);
                              }}
                              className={classes.form}
                              justify="center"
                            >
                              <CustomInput
                                id={`edit_title_${post.id}`}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder: "Title...",
                                  type: "text",
                                  value: editTitle,
                                  onChange: (e) => setEditTitle(e.target.value),
                                }}
                              />
                              <ReactQuill
                                theme="snow"
                                value={editBody}
                                onChange={setEditBody}
                                modules={modules}
                                formats={formats}
                                id={`edit_body_${post.id}`}
                                style={{ paddingBottom: "20px" }}
                                placeholder="Body..." // Added placeholder
                              />
                              <CustomInput
                                id={`edit_tag_category_${post.id}`}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  placeholder: "Tag Category...",
                                  type: "text",
                                  value: editTagCategory,
                                  onChange: (e) =>
                                    setEditTagCategory(e.target.value),
                                }}
                              />
                              <Button
                                type="submit"
                                color="primary"
                                className={classes.submitButton}
                              >
                                Save Changes
                              </Button>
                              <Button
                                color="danger"
                                className={classes.cancelButton}
                                onClick={() => {
                                  setEditingPostId(null);
                                }}
                              >
                                Cancel
                              </Button>
                            </form>
                          )}
                        </CardBody>
                      </Card>
                    </div>
                  </GridItem>
                ))}
              </GridContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForumPosts;
