import { mrAuto, mlAuto } from "assets/jss/material-kit-pro-react.js";

const servicesStyle = {
  title: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  mrAuto,
  mlAuto,
  services: {
    paddingTop: "10px",
    paddingBottom: "80px",
  },
  textCenter: {
    textAlign: "center",
  },

  info4: {
    padding: "0",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    "& p": {
      fontSize: "1rem",
    },
  },

  collapseWrapper: {
    overflow: "hidden !important",
  },

  expandableSpan: {
    "&, &:hover, &:focus": {
      cursor: "pointer",
      textDecoration: "underline",
      color: "#9c27b0", // Customize the link color
    },
  },
};

export default servicesStyle;
