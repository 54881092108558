// src/hooks/useAuth.js
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"; // Import useHistory
import Cookies from "js-cookie";

const useAuth = () => {
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory(); // Initialize useHistory

  useEffect(() => {
    const user = Cookies.get("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      setAuthToken(parsedUser.token);
    }
    setLoading(false);
  }, []);

  const login = (token, userId, r) => {
    // Add userId parameter
    const user = {
      token,
      user_id: userId, // Include the user_id in the user object
      // You can also include other user data if you want
    };
    Cookies.set("user", JSON.stringify(user), { expires: 7 });
    setAuthToken(token);
  };

  const logout = () => {
    Cookies.remove("user");
    setAuthToken(null);
    history.push("/"); // Redirect to the "/" route
  };

  return {
    authToken,
    login,
    logout,
    loading,
  };
};

export default useAuth;
